
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 10px !important;
}
.mt-1,
.my-1 {
  margin-top: 10px !important;
}
.mr-1,
.mx-1 {
  margin-right: 10px !important;
}
.mb-1,
.my-1 {
  margin-bottom: 10px !important;
}
.ml-1,
.mx-1 {
  margin-left: 10px !important;
}
.m-15 {
  margin: 15px !important;
}
.mt-15,
.my-15 {
  margin-top: 15px !important;
}
.mr-15,
.mx-15 {
  margin-right: 15px !important;
}
.mb-15,
.my-15 {
  margin-bottom: 15px !important;
}
.ml-15,
.mx-15 {
  margin-left: 15px !important;
}
.m-2 {
  margin: 20px !important;
}
.mt-2,
.my-2 {
  margin-top: 20px !important;
}
.mr-2,
.mx-2 {
  margin-right: 20px !important;
}
.mb-2,
.my-2 {
  margin-bottom: 20px !important;
}
.ml-2,
.mx-2 {
  margin-left: 20px !important;
}
.m-3 {
  margin: 30px !important;
}
.mt-3,
.my-3 {
  margin-top: 30px !important;
}
.mr-3,
.mx-3 {
  margin-right: 30px !important;
}
.mb-3,
.my-3 {
  margin-bottom: 30px !important;
}
.ml-3,
.mx-3 {
  margin-left: 30px !important;
}
.m-4 {
  margin: 40px !important;
}
.mt-4,
.my-4 {
  margin-top: 40px !important;
}
.mr-4,
.mx-4 {
  margin-right: 40px !important;
}
.mb-4,
.my-4 {
  margin-bottom: 40px !important;
}
.ml-4,
.mx-4 {
  margin-left: 40px !important;
}
.m-5 {
  margin: 50px !important;
}
.mt-5,
.my-5 {
  margin-top: 50px !important;
}
.mr-5,
.mx-5 {
  margin-right: 50px !important;
}
.mb-5,
.my-5 {
  margin-bottom: 50px !important;
}
.ml-5,
.mx-5 {
  margin-left: 50px !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 10px !important;
}
.pt-1,
.py-1 {
  padding-top: 10px !important;
}
.pr-1,
.px-1 {
  padding-right: 10px !important;
}
.pb-1,
.py-1 {
  padding-bottom: 10px !important;
}
.pl-1,
.px-1 {
  padding-left: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.pt-15,
.py-15 {
  padding-top: 15px !important;
}
.pr-15,
.px-15 {
  padding-right: 15px !important;
}
.pb-15,
.py-15 {
  padding-bottom: 15px !important;
}
.pl-15,
.px-15 {
  padding-left: 15px !important;
}
.p-2 {
  padding: 20px !important;
}
.pt-2,
.py-2 {
  padding-top: 20px !important;
}
.pr-2,
.px-2 {
  padding-right: 20px !important;
}
.pb-2,
.py-2 {
  padding-bottom: 20px !important;
}
.pl-2,
.px-2 {
  padding-left: 20px !important;
}
.p-3 {
  padding: 30px !important;
}
.pt-3,
.py-3 {
  padding-top: 30px !important;
}
.pr-3,
.px-3 {
  padding-right: 30px !important;
}
.pb-3,
.py-3 {
  padding-bottom: 30px !important;
}
.pl-3,
.px-3 {
  padding-left: 30px !important;
}
.p-4 {
  padding: 40px !important;
}
.pt-4,
.py-4 {
  padding-top: 40px !important;
}
.pr-4,
.px-4 {
  padding-right: 40px !important;
}
.pb-4,
.py-4 {
  padding-bottom: 40px !important;
}
.pl-4,
.px-4 {
  padding-left: 40px !important;
}
.p-5 {
  padding: 50px !important;
}
.pt-5,
.py-5 {
  padding-top: 50px !important;
}
.pr-5,
.px-5 {
  padding-right: 50px !important;
}
.pb-5,
.py-5 {
  padding-bottom: 50px !important;
}
.pl-5,
.px-5 {
  padding-left: 50px !important;
}
.m-n1 {
  margin: -10px !important;
}
.mt-n1,
.my-n1 {
  margin-top: -10px !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -10px !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -10px !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -10px !important;
}
.m-n15 {
  margin: -10px !important;
}
.mt-n15,
.my-n15 {
  margin-top: -10px !important;
}
.mr-n15,
.mx-n15 {
  margin-right: -10px !important;
}
.mb-n15,
.my-n15 {
  margin-bottom: -10px !important;
}
.ml-n15,
.mx-n15 {
  margin-left: -10px !important;
}
.m-n2 {
  margin: -10px !important;
}
.mt-n2,
.my-n2 {
  margin-top: -10px !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -10px !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -10px !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -10px !important;
}
.m-n3 {
  margin: -10px !important;
}
.mt-n3,
.my-n3 {
  margin-top: -10px !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -10px !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -10px !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -10px !important;
}
.m-n4 {
  margin: -10px !important;
}
.mt-n4,
.my-n4 {
  margin-top: -10px !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -10px !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -10px !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -10px !important;
}
.m-n5 {
  margin: -10px !important;
}
.mt-n5,
.my-n5 {
  margin-top: -10px !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -10px !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -10px !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -10px !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 10px !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 10px !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 10px !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 10px !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 10px !important;
  }
  .m-sm-15 {
    margin: 15px !important;
  }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 15px !important;
  }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 15px !important;
  }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 15px !important;
  }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 15px !important;
  }
  .m-sm-2 {
    margin: 20px !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 20px !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 20px !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 20px !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 20px !important;
  }
  .m-sm-3 {
    margin: 30px !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 30px !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 30px !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 30px !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 30px !important;
  }
  .m-sm-4 {
    margin: 40px !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 40px !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 40px !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 40px !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 40px !important;
  }
  .m-sm-5 {
    margin: 50px !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 50px !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 50px !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 50px !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 50px !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 10px !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 10px !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 10px !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 10px !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 10px !important;
  }
  .p-sm-15 {
    padding: 15px !important;
  }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 15px !important;
  }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 15px !important;
  }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 15px !important;
  }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 15px !important;
  }
  .p-sm-2 {
    padding: 20px !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 20px !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 20px !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 20px !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 20px !important;
  }
  .p-sm-3 {
    padding: 30px !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 30px !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 30px !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 30px !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 30px !important;
  }
  .p-sm-4 {
    padding: 40px !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 40px !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 40px !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 40px !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 40px !important;
  }
  .p-sm-5 {
    padding: 50px !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 50px !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 50px !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 50px !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 50px !important;
  }
  .m-sm-n1 {
    margin: -10px !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -10px !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -10px !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -10px !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -10px !important;
  }
  .m-sm-n15 {
    margin: -10px !important;
  }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -10px !important;
  }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -10px !important;
  }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -10px !important;
  }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -10px !important;
  }
  .m-sm-n2 {
    margin: -10px !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -10px !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -10px !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -10px !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -10px !important;
  }
  .m-sm-n3 {
    margin: -10px !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -10px !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -10px !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -10px !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -10px !important;
  }
  .m-sm-n4 {
    margin: -10px !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -10px !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -10px !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -10px !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -10px !important;
  }
  .m-sm-n5 {
    margin: -10px !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -10px !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -10px !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -10px !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -10px !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 10px !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 10px !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 10px !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 10px !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 10px !important;
  }
  .m-md-15 {
    margin: 15px !important;
  }
  .mt-md-15,
  .my-md-15 {
    margin-top: 15px !important;
  }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 15px !important;
  }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 15px !important;
  }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 15px !important;
  }
  .m-md-2 {
    margin: 20px !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 20px !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 20px !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 20px !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 20px !important;
  }
  .m-md-3 {
    margin: 30px !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 30px !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 30px !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 30px !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 30px !important;
  }
  .m-md-4 {
    margin: 40px !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 40px !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 40px !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 40px !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 40px !important;
  }
  .m-md-5 {
    margin: 50px !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 50px !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 50px !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 50px !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 50px !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 10px !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 10px !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 10px !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 10px !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 10px !important;
  }
  .p-md-15 {
    padding: 15px !important;
  }
  .pt-md-15,
  .py-md-15 {
    padding-top: 15px !important;
  }
  .pr-md-15,
  .px-md-15 {
    padding-right: 15px !important;
  }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 15px !important;
  }
  .pl-md-15,
  .px-md-15 {
    padding-left: 15px !important;
  }
  .p-md-2 {
    padding: 20px !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 20px !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 20px !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 20px !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 20px !important;
  }
  .p-md-3 {
    padding: 30px !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 30px !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 30px !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 30px !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 30px !important;
  }
  .p-md-4 {
    padding: 40px !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 40px !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 40px !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 40px !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 40px !important;
  }
  .p-md-5 {
    padding: 50px !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 50px !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 50px !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 50px !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 50px !important;
  }
  .m-md-n1 {
    margin: -10px !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -10px !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -10px !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -10px !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -10px !important;
  }
  .m-md-n15 {
    margin: -10px !important;
  }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -10px !important;
  }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -10px !important;
  }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -10px !important;
  }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -10px !important;
  }
  .m-md-n2 {
    margin: -10px !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -10px !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -10px !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -10px !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -10px !important;
  }
  .m-md-n3 {
    margin: -10px !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -10px !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -10px !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -10px !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -10px !important;
  }
  .m-md-n4 {
    margin: -10px !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -10px !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -10px !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -10px !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -10px !important;
  }
  .m-md-n5 {
    margin: -10px !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -10px !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -10px !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -10px !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -10px !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 10px !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 10px !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 10px !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 10px !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 10px !important;
  }
  .m-lg-15 {
    margin: 15px !important;
  }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 15px !important;
  }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 15px !important;
  }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 15px !important;
  }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 15px !important;
  }
  .m-lg-2 {
    margin: 20px !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 20px !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 20px !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 20px !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 20px !important;
  }
  .m-lg-3 {
    margin: 30px !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 30px !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 30px !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 30px !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 30px !important;
  }
  .m-lg-4 {
    margin: 40px !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 40px !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 40px !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 40px !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 40px !important;
  }
  .m-lg-5 {
    margin: 50px !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 50px !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 50px !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 50px !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 50px !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 10px !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 10px !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 10px !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 10px !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 10px !important;
  }
  .p-lg-15 {
    padding: 15px !important;
  }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 15px !important;
  }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 15px !important;
  }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 15px !important;
  }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 15px !important;
  }
  .p-lg-2 {
    padding: 20px !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 20px !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 20px !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 20px !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 20px !important;
  }
  .p-lg-3 {
    padding: 30px !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 30px !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 30px !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 30px !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 30px !important;
  }
  .p-lg-4 {
    padding: 40px !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 40px !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 40px !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 40px !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 40px !important;
  }
  .p-lg-5 {
    padding: 50px !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 50px !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 50px !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 50px !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 50px !important;
  }
  .m-lg-n1 {
    margin: -10px !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -10px !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -10px !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -10px !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -10px !important;
  }
  .m-lg-n15 {
    margin: -10px !important;
  }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -10px !important;
  }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -10px !important;
  }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -10px !important;
  }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -10px !important;
  }
  .m-lg-n2 {
    margin: -10px !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -10px !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -10px !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -10px !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -10px !important;
  }
  .m-lg-n3 {
    margin: -10px !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -10px !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -10px !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -10px !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -10px !important;
  }
  .m-lg-n4 {
    margin: -10px !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -10px !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -10px !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -10px !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -10px !important;
  }
  .m-lg-n5 {
    margin: -10px !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -10px !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -10px !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -10px !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -10px !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 10px !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 10px !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 10px !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 10px !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 10px !important;
  }
  .m-xl-15 {
    margin: 15px !important;
  }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 15px !important;
  }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 15px !important;
  }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 15px !important;
  }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 15px !important;
  }
  .m-xl-2 {
    margin: 20px !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 20px !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 20px !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 20px !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 20px !important;
  }
  .m-xl-3 {
    margin: 30px !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 30px !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 30px !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 30px !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 30px !important;
  }
  .m-xl-4 {
    margin: 40px !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 40px !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 40px !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 40px !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 40px !important;
  }
  .m-xl-5 {
    margin: 50px !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 50px !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 50px !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 50px !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 50px !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 10px !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 10px !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 10px !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 10px !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 10px !important;
  }
  .p-xl-15 {
    padding: 15px !important;
  }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 15px !important;
  }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 15px !important;
  }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 15px !important;
  }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 15px !important;
  }
  .p-xl-2 {
    padding: 20px !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 20px !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 20px !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 20px !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 20px !important;
  }
  .p-xl-3 {
    padding: 30px !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 30px !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 30px !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 30px !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 30px !important;
  }
  .p-xl-4 {
    padding: 40px !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 40px !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 40px !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 40px !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 40px !important;
  }
  .p-xl-5 {
    padding: 50px !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 50px !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 50px !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 50px !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 50px !important;
  }
  .m-xl-n1 {
    margin: -10px !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -10px !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -10px !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -10px !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -10px !important;
  }
  .m-xl-n15 {
    margin: -10px !important;
  }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -10px !important;
  }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -10px !important;
  }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -10px !important;
  }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -10px !important;
  }
  .m-xl-n2 {
    margin: -10px !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -10px !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -10px !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -10px !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -10px !important;
  }
  .m-xl-n3 {
    margin: -10px !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -10px !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -10px !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -10px !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -10px !important;
  }
  .m-xl-n4 {
    margin: -10px !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -10px !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -10px !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -10px !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -10px !important;
  }
  .m-xl-n5 {
    margin: -10px !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -10px !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -10px !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -10px !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -10px !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1336px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 10px !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 10px !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 10px !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 10px !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 10px !important;
  }
  .m-xxl-15 {
    margin: 15px !important;
  }
  .mt-xxl-15,
  .my-xxl-15 {
    margin-top: 15px !important;
  }
  .mr-xxl-15,
  .mx-xxl-15 {
    margin-right: 15px !important;
  }
  .mb-xxl-15,
  .my-xxl-15 {
    margin-bottom: 15px !important;
  }
  .ml-xxl-15,
  .mx-xxl-15 {
    margin-left: 15px !important;
  }
  .m-xxl-2 {
    margin: 20px !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 20px !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 20px !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 20px !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 20px !important;
  }
  .m-xxl-3 {
    margin: 30px !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 30px !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 30px !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 30px !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 30px !important;
  }
  .m-xxl-4 {
    margin: 40px !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 40px !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 40px !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 40px !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 40px !important;
  }
  .m-xxl-5 {
    margin: 50px !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 50px !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 50px !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 50px !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 50px !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 10px !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 10px !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 10px !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 10px !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 10px !important;
  }
  .p-xxl-15 {
    padding: 15px !important;
  }
  .pt-xxl-15,
  .py-xxl-15 {
    padding-top: 15px !important;
  }
  .pr-xxl-15,
  .px-xxl-15 {
    padding-right: 15px !important;
  }
  .pb-xxl-15,
  .py-xxl-15 {
    padding-bottom: 15px !important;
  }
  .pl-xxl-15,
  .px-xxl-15 {
    padding-left: 15px !important;
  }
  .p-xxl-2 {
    padding: 20px !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 20px !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 20px !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 20px !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 20px !important;
  }
  .p-xxl-3 {
    padding: 30px !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 30px !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 30px !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 30px !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 30px !important;
  }
  .p-xxl-4 {
    padding: 40px !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 40px !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 40px !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 40px !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 40px !important;
  }
  .p-xxl-5 {
    padding: 50px !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 50px !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 50px !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 50px !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 50px !important;
  }
  .m-xxl-n1 {
    margin: -10px !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -10px !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -10px !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -10px !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -10px !important;
  }
  .m-xxl-n15 {
    margin: -10px !important;
  }
  .mt-xxl-n15,
  .my-xxl-n15 {
    margin-top: -10px !important;
  }
  .mr-xxl-n15,
  .mx-xxl-n15 {
    margin-right: -10px !important;
  }
  .mb-xxl-n15,
  .my-xxl-n15 {
    margin-bottom: -10px !important;
  }
  .ml-xxl-n15,
  .mx-xxl-n15 {
    margin-left: -10px !important;
  }
  .m-xxl-n2 {
    margin: -10px !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -10px !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -10px !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -10px !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -10px !important;
  }
  .m-xxl-n3 {
    margin: -10px !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -10px !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -10px !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -10px !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -10px !important;
  }
  .m-xxl-n4 {
    margin: -10px !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -10px !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -10px !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -10px !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -10px !important;
  }
  .m-xxl-n5 {
    margin: -10px !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -10px !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -10px !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -10px !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -10px !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}