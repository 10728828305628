@import "./assets/styles/common";

.w-100 {
  width: 100%;
}

.full-width {
  float: left;
  width: 100%;
}

#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.leftSidebarMenu {
  a {
    color: #888;
  }
}

.page-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.videoTitle {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.publishedDate {
  color: #777;
  font-size: 14px;
}

.floating-btn {
  font-size: 30px;
  position: fixed;
  z-index: 12;
  width: 50px;
  height: 50px;
  right: 20px;
  bottom: 20px;
  color: #fff;
  cursor: pointer;
  background-color: #fa541c;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 1;
  border: 2px solid transparent;

  &:hover {
    color: #fa541c;
    border-color: #fa541c;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import "assets/styles/mixins";

.cardVideo {
  position: relative;
  height: 100%;
  min-height: 240px;
  .ant-card-body {
    padding: 0;
  }
}

.videoCardBlock {
  float: left;
  width: 100%;
  position: relative;

  .videoDuration {
    position: absolute;
    right: 10px;
    top: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    line-height: 1;
    padding: 3px 5px;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    font-weight: 500;
  }

  .videoCardInfoBlock {
    float: left;
    width: 100%;
    padding: 15px;
  }

  .videoInfoImageBlock {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    float: left;
    width: 100%;
    position: relative;
    z-index: 2;

    img {
      width: 100%;
    }
  }
}

.cardFolder {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  min-height: inherit;

  .ant-card-body {
    padding: 20px;
  }

  .videoCardInfoBlock {
    padding-top: 0;
    padding-bottom: 0;
  }

  /* .folderCardBlock {
    text-align: center;
    padding-top: 45px;
    padding-bottom: 45px;
  } */
}

.folderBtn {
  float: left;
  width: 100%;
  height: auto;
  border: none;
  box-shadow: none;

  .folderIcon {
    transition: all 0.3s ease;
  }

  &:hover {
    .fodlerIcon {
      transform: scale(1.3);
    }
  }
}

.folderIcon {
  font-size: 70px;
  color: #29b6f6;
}

.folderTitle {
  text-align: center;
}

.folderInfo {
  text-align: center;
}

.floatingFolderOptions {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .folderDropDownIcon {
    color: #666;
    font-size: 24px;
  }
}

.delete-folder-btn {
  color: #e64a19;
}


.text-align-center {
  text-align: center;
}
.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.font-size-lg {
  font-size: 20px;
}

.page-layout {
  padding: 20px;
}

.bg-white {
  background-color: white;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}