.uppy-Dashboard-browse {
   display: block;
   width: 100%;
   max-width: 320px;
   margin: 10px auto;
   background-color: white;
   color: var(--primary);
   height: 50px;
   text-align: center;
   border: none;
   cursor: pointer;
   border-radius: 3px;
   font-size: 0;
   position: relative;
   overflow: hidden;

   &::after {
      content: "Choose File";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      z-index: 2;
      background-color:var(--primary);
      font-size: 20px;
   }

   &:hover {
      &::after {
         background-color: #1890ff;
      }
   }
}


[data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
  border: none;
}

.uppy-Dashboard-inner {
  border: none;
}


.meander-upload {

   .uppy-Dashboard-AddFiles {
      justify-content: flex-start;
   }

   .uppy-Dashboard-AddFiles-title {
      padding-top: 140px;
      position: relative;

      &::before {
         content: "";
         position: absolute;
         left: 50%;
         top: 20px;
         background-image: url(../../assets/images/cloud_upload.svg);
         background-repeat: no-repeat;
         background-position: top center;
         background-size: 100px;
         width: 100px;
         height: 100px;
         margin-left: -50px;
         display: block;
         opacity: 0.3;

      }
   }
}