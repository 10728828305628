@import "../../assets/styles/mixins";

#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
  height: 100%;
}

.leftSidebarMenu {
  a {
    color: #888;
  }
}

.page-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.videoTitle {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.publishedDate {
  color: #777;
  font-size: 14px;
}

.videoDuration {
  position: absolute;
  right: 10px;
  top: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  line-height: 1;
  padding: 3px 5px;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.uploadVideoDrawer {
  /* width: 100% !important; */

  .ant-drawer-body {
    padding: 0;
  }

  &.ant-drawer-open {
    width: 100% !important;
  }

  .uppy-Dashboard-inner,
  .uppy-Dashboard-AddFiles {
    border: none;
  }

  @include bp(md) {
    /* width: 500px !important; */
    &.ant-drawer-open {
      width: 500px !important;
    }
  }
}

.ant-drawer-open {
}

.createFolderMenuItem {
  padding: 10px 0 !important;
  height: auto !important;
  background-color: #e5e5e5;
  margin-top: -8px !important;
  margin-bottom: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .createFolderBtn {
    display: flex;
    align-items: center;
    margin-left: 3px;
    margin-right: 3px;
  }

  .createFolderBtnIcon {
    font-size: 19px;
    margin-right: 5px;
  }
}

.uploadSelectfolderBlock {
  padding: 7px;
}

.uploadFileUppyBlock {
  height: calc(100% - 50px);
  padding: 10px;

  > div {
    border: 2px dashed #eee;
    border-radius: 5px;
    background-color: #fafafa;
  }

  .uppy-Dashboard-AddFiles-title {
    font-size: 30px;
    color: #888;
  }

  .uppy-Dashboard-browse {
    cursor: pointer;

    &:hover {
      color: #333;
    }
  }
}

.my-account-dropdown {
  height: auto;
  line-height: 1;
  padding-right: 0;
  padding-left: 0px;
}

.copyCodeButtonsList {
  border-right: none !important;
  .ant-menu-item {
    margin-bottom: 6px !important;
    margin-top: 6px !important;
    border: 1px solid #eee;
    border-radius: 3px;
    color: #888;

    &.ant-menu-item-selected {
      border-color: #1890ff;
      background-color: #1890ff;
      color: #fff;
    }
  }
}

.newActionsList {
  .createFolderBtn {
    margin-left: 3px;
    margin-right: 3px;
  }
}

.switchAccountSelect {
  color: white;
  font-size: 16px;

  .ant-select-arrow {
    color: #ccc;
  }
}

.top-header-col {
  line-height: 1;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-left: 7px;
  padding-right: 7px;
}


.topHeaderIconBtn {
  padding: 0;
  line-height: 1;
  width: 40px;
  font-size: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.text {
    font-size: 16px;
    color: white;
  }

  .topHeaderIcon {
    width: 40px;
    color: #eee;
  }
}