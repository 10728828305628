.embed-code-block {
  background-color: white;

  .embed-code-input {
    width: 100%;
    background-color: #eee;
    border: 1px solid #ccc;
    resize: none;
    font-size: medium;
    border-radius: 5px;
    font-weight: 500;
  }
}

.embed-copy-block {
  margin-top: 10px;
}

.embed-copy-btn {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  border-radius: 100px;
}

.videoBlock {
  position: relative;
  float: left;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  padding-bottom: calc(.56471 * 100%);
  overflow: hidden;
  z-index: 2;

  .videoIconLoading {
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    font-size: 100px;
    color: #aaa;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
  }

  .playBtn {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0%;
    top: 0%;
    margin: 0;
    z-index: 6;
    background-color: transparent;
    padding: 0;
    border: none;
    display: flex;
   align-items: center;
   color: white;
   justify-content: center;
   opacity: 0.6;
   cursor: pointer;

   svg {
      width: 50px;
      height: 50px;
   }

   &:hover {
      opacity: 1;
   }
  }
}


.video-content {
  padding: 15px 20px;
  .video-title {
    font-size: 16px;
    font-weight: 700;
  }

  .video-date {
    color: #777;
    font-size: 14px;
  }
}