.each-report-block {
   height: 150px;
   border: 1px solid #ddd;
   border-radius: 3px;
   background-color: white;
   padding: 25px;

   .storage-capacity-block {
      color: #999;
      font-size: 18px;
   }
}