:root {
  --primary: #3d5af1;
  --secondary: #3e3636;

}

.position-relative {
  position: relative;
}

.main {
  padding: 12px 12px 25px 12px;
}

.brandingLogoBlock {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 100%;
}

.my-1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-05 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.full-width {
  float: left;
  width: 100%;
}


@import "margin-paddings";